<template>

<div>
 


       <b-modal
      ref="modalRetirar"
      centered
      hide-footer
            @hidden="closeModal"
      :title="title"
      size="sm"
    >
      <div class="d-block text-center">

  
             <b-row>

                   <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                          >

                               <b-alert
                          variant="secondary"
                          show
                          style="margin-top:10px;margin-bottom: 10px;"
                        >
                          <div class="alert-body">
                            <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="40px"  ></b-skeleton>

                            <p v-else class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency2">USD</small></p>
                            <p style="font-size: 12px;    margin: 0;">Saldo disponible para retirar</p>

                          
                          </div>
                        </b-alert>

                        

                          </b-col>

                          <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-top: 5px;margin-bottom: 5px;"
                        >

                          <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmit"
                          >

                          <b-row>

                                 <b-col
                                    cols="12"
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      xl="12"
                                    >

                                    

                                                <p
                                        class="textLabel"
                                        style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                                      >
                                        ¿Cuánto vas a retirar? (*):</p>

                                        <AutoNumericVue
                                        v-model="mount"
                                        required
                                        :readonly="isDisabled"
                                        type="text" inputmode="decimal"
                                        @blur="onBlurMount"
                                        class="autonumeric_input"
                                        :options="json_opciones"
                                        :placeholder="'$0.00'"
                                    ></AutoNumericVue>

                                    </b-col>

                                    <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >

                                    <b-alert
                                        variant="primary"
                                        show
                                        style="margin-top:0px"
                                      >
                                        <div class="alert-body">
                                          <p style=" text-align: center;font-size:12px">Monto máximo de retiro diario de su cuenta</p>
                                          <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="20px"  ></b-skeleton>
                                          <p v-else style="text-align: center;font-size:25px;font-weight: bold; margin-bottom: 0px;">${{maxRetiro}} <span style="font-size:12px">USD</span></p>
                                          
                                      
                                        
                                        </div>
                                      </b-alert>

                                      </b-col>

                    

                                       <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >
                                                <p
                                                  class="textLabel"
                                                  style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
                                                >
                                                  Ingrese su código PIN (*): <feather-icon
                                                                    icon="HelpCircleIcon"
                                                                    size="16"
                                                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                    title="Código PIN de seguridad"
                                                                    style="margin-right: 5px;cursor:pointer;"
                                                                    /> </p>

                                                <PincodeInput
                                                  v-model="pin"
                                                  placeholder="-"
                                                  :length="6"
                                                  :autofocus="false"
                                                  :disabled="isDisabled"
                                                  :secure="true"
                                                  required
                                                />

                                              </b-col>




                                              <b-col
                                              cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >

                                                <b-button
                                                  id="btn_transferir"
                                                  ref="btn_transferir"
                                                  type="submit"
                                                  variant="primary"
                                                  block
                                                  :disabled="isDisabled"
                                                >

                                                  Retirar dinero

                                                </b-button>

                                              </b-col>

                                         


                          </b-row>
                          
                          
                          </b-form>

                          <b-alert
                          variant="primary"
                          show
                          style="margin-top:15px"
                        >
                          <div class="alert-body">
                            <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                             <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) El monto mínimo de retiro es de <b>${{minRetiro}} USD.</b></p>
                             <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                                <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) Se cobrará una comisión de <b>${{comisionRetiro}} USD</b> </p>
                                <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="10px"  ></b-skeleton>
                        <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) La efectivización del dinero es de <b>{{horasRetiro}}</b>  </p>
                          
                          
                          </div>
                        </b-alert>

                          
                    

                        </b-col>


             </b-row>
            
        </div>

        </b-modal>


      </div>


</template>

<script>
import {
  VBTooltip, BButton,VBPopover,BForm,BAlert, BRow, BCol, BCard,BSpinner, BSkeleton
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';

export default {
  components: {
    BButton,
    BForm,
    BAlert,
    BSkeleton,
    BSpinner,
    BRow,
    BCol,
    BCard,
    AutoNumericVue,
    PincodeInput
  },
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['userId','tokenAuth', 'opcionRetiro'],
  data() {


    return {
        pin:"",
        isDisabled:false,
        loading:false,
        mount:"",
        saldoDisponible:0, horasRetiro:"", minRetiro:0,comisionRetiro:0,maxRetiro:0,
        source:"",
        title:"",
        json_opciones:{},


    
    }
  },
  computed: {



  },
  watch: {


 

  },
  mounted() {



      



 


  this.source = this.opcionRetiro;

  if(this.source === "Banco"){
    this.getSaldoRetiro();

    this.title="Retiro de dinero";
    this.$refs.modalRetirar.show();

  }else{

    if(this.source === "Wester"){

      this.$eventBus.$emit('reiniciarRetiro')
             this.$toast.error("Funcionalidad no disponible por el momento", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

    }

  }








  },
  methods: {
          closeModal(){
  
       this.$eventBus.$emit('reiniciarRetiro')

    },

    getSaldoRetiro(){
      this.loading=true;
      this.isDisabled=true;
       

      const data_json = { userId: this.userId }
            const data_string = JSON.stringify(data_json)
  
            const dataEncripted = this.$encryptBackoffice.encrypt(data_string)


    this.$https.post('/profile/getBalanceComisiones3/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
        

       
      if (response.data.code == 200) {
        this.loading=false;
      this.isDisabled=false;

    
          this.saldoDisponible= response.data.balance;
          this.horasRetiro=response.data.horasRetiro;
          this.minRetiro=Number(response.data.minRetiro).toFixed(2);
          this.maxRetiro=response.data.maxRetiro;
          this.comisionRetiro=response.data.comisionRetiro;


          this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxRetiro, modifyValueOnWheel: false };

   

      } else {

        this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')

          

          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
           this.getSaldoRetiro();
        }
      }
    }).catch(error => {
         this.getSaldoRetiro();
    })
    },


    onChange(event) {


     
      this.source = event.target.value;
     

       if(this.source === "Banco"){

 

           
             this.title="Retiro de dinero"

             this.$refs.modalRetirar.show();


       }else{
  


         if(this.source === "Wester"){
          this.$eventBus.$emit('reiniciarRetiro')
             this.$toast.error("Funcionalidad no disponible por el momento", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
        }

       }

      



    },
     onSubmit(event) {
      event.preventDefault()

     

        this.mount= Number(this.mount).toFixed(2);


      const userId_json = {
        userId: this.userId, pin: this.pin, mount: this.mount, source:this.source
      }



      const user_string = JSON.stringify(userId_json)

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
    
      this.isDisabled = true

      document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Retirando'

      this.$https.post('/banks/withdraw/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
      this.isDisabled = false

    
  
        document.getElementById('btn_transferir').innerHTML = 'Retirar dinero';
       // this.$eventBus.$emit('reiniciarListadoInterbilletera');

        if (response.data.code == 200) {

           this.$refs.modalRetirar.hide();

        

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

          this.$eventBus.$emit('reiniciarRetiro2');
          this.$eventBus.$emit('reiniciarRetiro2')



        } else {
          this.isDisabled = false

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      

    
    },
    onBlurMount(){



      if(this.mount == null || this.mount == "null" || this.mount ==""){
        this.mount ="";



        }else{

          if(Number(this.mount) < Number(this.minRetiro)){

             this.mount ="";
                  this.$toast.error("La cantidad a retirar debe ser mayor a la cantidad mínima de retiro de $" +this.minRetiro+" USD" , {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })



          }else{

            if(Number(this.mount) > Number(this.maxRetiro)){
              this.mount="";

              this.$toast.error("La cantidad a retirar debe ser menor al monto máximo de retiro por día disponible para su cuenta que es de $" +this.maxRetiro+" USD" , {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })
            }else{

              
            if(Number(this.mount) > Number(this.saldoDisponible)){
              

              this.mount="";

               this.$toast.error("La cantidad a retirar debe ser menor a su saldo disponible de $" +this.saldoDisponible+" USD" , {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })

      


          }

            }




          }

       

          


        }

    },

    

   
  }
}
</script>

<style lang="scss">




</style>
